import "./App.css";

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LockerPage from "./components/template/Lockers/LockerPage";
import TechnicalVideoCall from "./components/template/stringee/TechnicalVideoCall";
import SalesVideoCall from "./components/template/stringee/SalesVideoCall";
import Dashboard from "./components/template/dashboard/Dashboard";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route
            exact
            path="/:slug/technical-support/videoattendant"
            element={<TechnicalVideoCall />}
          />
          <Route exact path="/:slug/call" element={<LockerPage />} />
          <Route
            exact
            path="/:slug/sales/videoattendant"
            element={<SalesVideoCall />}
          />
        </Routes>
      </Router>
    </>
  );
}
export default App;
